// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "lang": "TM",
                    "PLANETS_TR": {
                        "La": "La",
                        "Ke": "Ke",
                        "Ve": "Ve",
                        "Su": "Su",
                        "Mo": "Mo",
                        "Ma": "Ma",
                        "Ra": "Ra",
                        "Ju": "Ju",
                        "Sa": "Sa",
                        "Me": "Me",
                        "Mt": "Mt",
                        "Pa": "Pa",
                        "Thuma": "Thuma",
                        "ViyathiPatha": "ViyathiPatha",
                        "Parivesha": "Parivesha",
                        "InthiraThanusu": "InthiraThanusu",
                        "UpaKethu": "UpaKethu"
                    },
                    "RASI_TR": {
                        "Mesham": "Aries",
                        "Rishabam": "Taurus",
                        "Mithunam": "Gemini",
                        "Kadakam": "Cancer",
                        "Simham": "Leo",
                        "Kanni": "Virgo",
                        "Thulam": "Libra",
                        "Viruchigam": "Scorpio",
                        "Dhanusu": "Sagittarius",
                        "Magaram": "Capricorn",
                        "Kumbam": "Aquarius",
                        "Meenam": "Pisces"
                    },
                    "NAK_TR": {
                        "Aswini": "Aswini",
                        "Bharani": "Bharani",
                        "Krithika": "Krithika",
                        "Rohini": "Rohini",
                        "Mrigasira": "Mrigasira",
                        "Ardra": "Ardra",
                        "Punarvasu": "Punarvasu",
                        "Pushya": "Pushya",
                        "Ashlesha": "Ashlesha",
                        "Makha": "Makha",
                        "Puram": "Puram",
                        "Uttara": "Uttara",
                        "Hasta": "Hasta",
                        "Chitra": "Chitra",
                        "Swathi": "Swathi",
                        "Visakha": "Visakha",
                        "Anusam": "Anusam",
                        "Kettai": "Kettai",
                        "Moola": "Moola",
                        "Puradam": "Puradam",
                        "Uthiradam": "Uthiradam",
                        "Thiruvonam": "Thiruvonam",
                        "Avitam": "Avitam",
                        "Sathayam": "Sathayam",
                        "Poorataathi": "Poorataathi",
                        "Uthirattathi": "Uthirattathi",
                        "Revathi": "Revathi"
                    },
                    "TITLE_TR": {
                        "Dasa": "Dasa",
                        "Puthi": "Puthi",
                        "Antharan": "Antharan",
                        "Planet": "Planet",
                        "Degree": "Degree",
                        "Nakshatra": "Nakshatra",
                        "Rasi": "Rasi"
                    },
                    "JAMAKOL_TR": {
                        "Ud": "Ud",
                        "Aa": "Aa",
                        "Ka": "Ka"
                    }
                },
            },
            tm: {
                translation: {
                    "lang": "EN",
                    "PLANETS_TR": {
                        "La": "லக்",
                        "Ke": "கே",
                        "Ve": "சுக்",
                        "Su": "சூரி",
                        "Mo": "சந்",
                        "Ma": "செ",
                        "Ra": "ரா",
                        "Ju": "குரு",
                        "Sa": "சனி",
                        "Me": "புத",
                        "Mt": "மா",
                        "Pa": "பாம்பு",
                        "Thuma": "தூமா",
                        "ViyathiPatha": "வி. பாத",
                        "Parivesha": "பரி",
                        "InthiraThanusu": "இந்திர",
                        "UpaKethu": "உ.கேது"
                    },
                    "RASI_TR": {
                        "Mesham": "மேஷம்",
                        "Rishabam": "ரிஷபம்",
                        "Mithunam": "மிதுனம்",
                        "Kadakam": "கடகம்",
                        "Simham": "சிம்மம்",
                        "Kanni": "கன்னி",
                        "Thulam": "துலாம்",
                        "Viruchigam": "விரிச்சிகம்",
                        "Dhanusu": "தனுசு",
                        "Magaram": "மகரம்",
                        "Kumbam": "கும்பம்",
                        "Meenam": "மீனம்"
                    },
                    "NAK_TR": {
                        "Aswini": "அசுவினி",
                        "Bharani": "பரணி",
                        "Krithika": "கிருத்திகை",
                        "Rohini": "ரோஹிணி",
                        "Mrigasira": "மிரிகசீரா",
                        "Ardra": "திருவாதிரை",
                        "Punarvasu": "புனர்பூசம்",
                        "Pushya": "புசம்",
                        "Ashlesha": "ஆயிலியம்",
                        "Makha": "மகம்",
                        "Puram": "பூரம்",
                        "Uttara": "உத்திரா",
                        "Hasta": "ஹஸ்தா",
                        "Chitra": "சித்திரை",
                        "Swathi": "சுவாதி",
                        "Visakha": "விசாகம்",
                        "Anusam": "அனுசம்",
                        "Kettai": "கேட்டை",
                        "Moola": "மூலம்",
                        "Puradam": "பூராடம்",
                        "Uthiradam": "உத்திராடம்",
                        "Thiruvonam": "திருவோணம்",
                        "Avitam": "அவிட்டம்",
                        "Sathayam": "சதயம்",
                        "Poorataathi": "பூரட்டாதி",
                        "Uthirattathi": "உத்தரட்டாதி",
                        "Revathi": "ரேவதி"
                    },
                    "TITLE_TR": {
                        "Dasa": "தசா",
                        "Puthi": "புத்தி",
                        "Antharan": "அந்தரண்",
                        "Planet": "கிரகம்",
                        "Degree": "பாகை",
                        "Nakshatra": "நட்சத்திரம்",
                        "Rasi": "ராசி"
                    },
                    "JAMAKOL_TR": {
                        "Ud": "உத",
                        "Aa": "ஆரூ",
                        "Ka": "கவி"
                    }
                },
            },
            // Add other languages as necessary
        },
        lng: 'tm', // Default language
        fallbackLng: 'tm',
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;
