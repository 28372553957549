import { cloneDeep } from "lodash";
import { HOROSCOPE_CONSTANTS } from "../constants/HoroscopeConstants";

export class KattamUtil {

    setDasas = (kattamData: any, dob: any) => {
        const ctDate = new Date();
        kattamData.dasas = cloneDeep(kattamData.dasa_details.dasa_infos);

        kattamData.dasas.forEach((dasa: any, index) => {
            dasa.start_date = new Date(dasa.start_date);
            dasa.end_date = new Date(dasa.end_date);


            if (dasa.start_date.getTime() <= ctDate.getTime() && ctDate.getTime() <= dasa.end_date.getTime()) {
                dasa.is_current = true;
                kattamData.selectedDasa = dasa;
            }
        });

        if (!kattamData.selectedDasa) {
            kattamData.dasas[ 0 ].is_current = true;
            kattamData.selectedDasa = kattamData.dasas[ 0 ];
        }

        if (kattamData.selectedDasa) {
            kattamData.dasa_kaalam = this.calculateAge(ctDate, kattamData.selectedDasa.end_date);
        }

        this.setBukthis(kattamData);
    };

    setBukthis = (kattamData: any) => {
        const ctDate = new Date();
        kattamData.buthis = kattamData.selectedDasa.puthis;

        kattamData.buthis.forEach((buthi: any) => {
            buthi.start_date = new Date(buthi.start_date);
            buthi.end_date = new Date(buthi.end_date);
            if (buthi.start_date.getTime() <= ctDate.getTime() && ctDate.getTime() <= buthi.end_date.getTime()) {
                buthi.is_current = true;
                kattamData.selectedBukthi = buthi;
            }
        });

        if (!kattamData.selectedBukthi) {
            kattamData.buthis[ 0 ].is_current = true;
            kattamData.selectedBukthi = kattamData.buthis[ 0 ];
        }

        kattamData.bukthi_kaalam = this.calculateAge(ctDate, kattamData.selectedBukthi.end_date);
        this.setAntharams(kattamData);
    };

    setAntharams = (kattamData: any) => {
        const ctDate = new Date();
        kattamData.antaramsFiltered = kattamData.dasa_details.dasa_antarams.filter((anthram: any) =>
            anthram.d3 === kattamData.selectedBukthi.d2 && anthram.b3 === kattamData.selectedBukthi.b2
        );

        kattamData.antaramsFiltered.forEach((anthram: any) => {
            anthram.start_date = new Date(anthram.start_date);
            anthram.end_date = new Date(anthram.end_date);

            if (anthram.start_date.getTime() <= ctDate.getTime() && ctDate.getTime() <= anthram.end_date.getTime()) {
                anthram.is_current = true;
                kattamData.selectedAntaram = anthram;
                kattamData.antaram_kaalam = this.calculateAge(ctDate, anthram.end_date);
            }
        });
    };


    getKattam = (chartData: any) => {

        if (chartData && chartData.planetary_data) {
            const chartInfo = chartData.planetary_data;
            const profile = chartData.profile;
            const ctDate = new Date();

            const kattamData = cloneDeep(chartInfo);
            kattamData.outer_planets = chartData.outer_planets;

            kattamData.first_name = profile.first_name;
            kattamData.birth_location = profile.birth_location;
            kattamData.dob = new Date(profile.dob);

            this.setDasas(kattamData, kattamData.dob);

            kattamData.age = kattamData.dob.getTime() >= ctDate.getTime()
                ? this.calculateAge(ctDate, kattamData.dob)
                : this.calculateAge(kattamData.dob, ctDate);

            kattamData.neram = HOROSCOPE_CONSTANTS.NERAM[ kattamData.dob.getDay() ];

            return kattamData;
        }

    };




    calculateAge = (startDate, endDate) => {
        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();

        if (months < 0 || (months === 0 && endDate.getDate() < startDate.getDate())) {
            years--;
            months += 12;
        }

        let days = endDate.getDate() - startDate.getDate();
        if (days < 0) {
            const prevMonth = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 0);
            days += prevMonth.getDate();
            months--;
        }

        return `${years} Yrs ${months} Mon ${days} days`;
    };

}
