import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PersonToday from './jothidam/PersonToday';

function Home({ onLogout }: { onLogout: () => void; }) { // Accept onLogout function as a prop

    const [ isLanding, setIsLanding ] = useState(true);
    const navigate = useNavigate();
    const routeLocation = useLocation();

    useEffect(() => {
        setIsLanding(false);

        if (routeLocation.pathname === '/') {
            setIsLanding(true);
        }

        if (window.location.search.includes('app')) {
            navigate('/jd/profile');
        }
    }, [ routeLocation ]);

    const handleLogout = () => {
        onLogout(); // Call the logout function passed as a prop
        navigate('/login'); // Redirect to the login page
    };

    return (
        <div>
            <nav>
                <ul style={ { listStyle: 'none', display: 'flex' } }>
                    <li style={ { marginRight: 20 } }>
                        <Link to="/jd/profile">Jadhakam</Link>
                    </li>
                    <li style={ { marginRight: 20 } }>
                        <Link to="/thithi">Thithi</Link>
                    </li>
                    {/* <li style={ { marginRight: 20 } }>
                        <Link to="/planets">Planets</Link>
                    </li> */}
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li style={ { marginLeft: 20 } }>
                        <button onClick={ handleLogout }>Logout</button> {/* Logout button */ }
                    </li>
                </ul>
            </nav>

            { isLanding && (
                <PersonToday />
            ) }

            <Outlet />
        </div>
    );
}

export default Home;
