// src/Kattam.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Kattam.css'; // Assuming you have a corresponding CSS file

// Define prop types
interface Planet {
    planet: string;
    full_degree: number;
}

interface House {
    order: number;
    house_number: number;
    planetary_positions?: Array<any>; // Replace with your actual type
    upa_graham_positions?: Array<any>; // Replace with your actual type
}

interface ChartInfo {
    first_name?: string;
    dob?: string; // Use Date if you're handling dates
    selectedAntaram?: Record<string, string>;
    dasa_kaalam?: string;
}

interface KattamProps {
    chartInfo: ChartInfo;
    houses: House[];
    isUpaGragam: boolean;
    hInfo: boolean;
    title: string;
    isRasi: boolean;
    outerPlanets?: Planet[] | null; // Allow null
}

const Kattam: React.FC<KattamProps> = ({
    chartInfo,
    houses,
    isUpaGragam,
    hInfo,
    title,
    isRasi,
    outerPlanets,
}) => {
    const { t } = useTranslation();

    // Check if outerPlanets is not null and has more than 7 planets
    const hasOuterPlanets = outerPlanets && outerPlanets.length > 7;

    const InnerTemplate = ({ houses, chartInfo, title, isRasi, isUpaGragam }) => {
        return (
            <div className="flex-chart" key={ houses.length } >
                { houses.map((item, i) => (

                    <React.Fragment key={ i }>
                        <div style={ { order: item.order } } className={ `house ${item.house_number === 1 ? 'lagna' : ''} ${item.mb_normal_degree ? 'mb' : ''}` }>

                            { item.jamakol_positions && item.jamakol_positions.map((jk, index) => (
                                <div key={ index } style={ { display: 'inline-block', color: '#178835' } }>
                                    { t(`JAMAKOL_TR.${jk.jk_planet}`) } &nbsp;
                                </div>
                            )) }

                            { !isUpaGragam ? (
                                item.planetary_positions.map((pp, index) => (
                                    <div key={ index } style={ { display: 'inline-block' } } className={ `planet ${pp.is_retrograde ? 'retrograte' : ''} 
                                    ${pp.thick_palam === item.house_number ? 'thick_palam' : ''} ${pp.neesh_palam === item.house_number ? 'neesh_palam' : ''} 
                                    ${pp[ pp.planet + '_level' ]}` }>
                                        { t(`PLANETS_TR.${pp.nak_planet}`) }<span>{ isRasi && ` - ${pp.normal_degree.toFixed(1)}` } &nbsp;&nbsp;&nbsp; </span>
                                    </div>
                                ))
                            ) : (
                                item.upa_graham_positions.map((ug, index) => (
                                    <div key={ index } style={ { display: 'inline-block', color: 'blue' } } className={ ug.is_retrograde ? 'retrograte' : '' }>
                                        { t(`PLANETS_TR.${ug.nak_planet}`) }<span>{ isRasi && ` - ${ug.normal_degree.toFixed(1)}` }</span>
                                    </div>
                                ))
                            ) }


                        </div>
                        { (item.order === 5 || item.order === 7) && (
                            <div className="house-blank" style={ { order: item.order } }>
                                { item.order === 5 && (
                                    <div style={ { textAlign: 'center' } }>
                                        <span style={ { fontSize: '14px' } }>{ title }</span><br />
                                        <span style={ { fontSize: '14px' } }>{ chartInfo.first_name || 'Today' }</span><br />
                                        <span>{ new Date(chartInfo.dob).toLocaleDateString() }</span>
                                    </div>
                                ) }
                                { item.order === 7 && isRasi && chartInfo.selectedAntaram && (
                                    <span>
                                        <span>{ t(`PLANETS_TR.${chartInfo.selectedAntaram[ 'd3' ]}`) } (த) { chartInfo.dasa_kaalam }</span>
                                        <div>
                                            { t('TITLE_TR.Dasa') }: { t(`PLANETS_TR.${chartInfo.selectedAntaram[ 'd3' ]}`) } (த) &nbsp;{ t(`PLANETS_TR.${chartInfo.selectedAntaram[ 'b3' ]}`) } (பு) &nbsp;{ t(`PLANETS_TR.${chartInfo.selectedAntaram[ 'a3' ]}`) } (அ)
                                        </div>
                                    </span>
                                ) }
                            </div>
                        ) }
                    </React.Fragment>


                )) }
            </div>
        );
    };

    return (
        <div>
            { hasOuterPlanets ? (
                <div className="outer-panel">
                    <div className="left-outer-panel">
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 0 ].planet}`) } { outerPlanets[ 0 ].full_degree.toFixed(2) }</div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 1 ].planet}`) } { outerPlanets[ 1 ].full_degree.toFixed(2) }</div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 2 ].planet}`) } { outerPlanets[ 2 ].full_degree.toFixed(2) }</div>
                    </div>
                    <div className="center-outer-panel">
                        <div className="top-outer-panel">
                            <div className="outer-block blank"></div>
                            <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 7 ].planet}`) } { outerPlanets[ 7 ].full_degree.toFixed(2) }</div>
                            <div className="outer-block blank"></div>
                            <div className="outer-block blank"></div>
                        </div>
                        <div className="inner-panel">
                            <InnerTemplate houses={ houses } chartInfo={ chartInfo } title={ title } isRasi={ isRasi } isUpaGragam={ isUpaGragam } />
                        </div>
                        <div className="bottom-outer-panel">
                            <div className="outer-block blank"></div>
                            <div className="outer-block blank"></div>
                            <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 3 ].planet}`) } { outerPlanets[ 3 ].full_degree.toFixed(2) }</div>
                            <div className="outer-block blank"></div>
                        </div>
                    </div>
                    <div className="right-outer-panel">
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 6 ].planet}`) } { outerPlanets[ 6 ].full_degree.toFixed(2) }</div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 5 ].planet}`) } { outerPlanets[ 5 ].full_degree.toFixed(2) }</div>
                        <div className="outer-block blank"></div>
                        <div className="outer-block">{ t(`PLANETS_TR.${outerPlanets[ 4 ].planet}`) } { outerPlanets[ 4 ].full_degree.toFixed(2) }</div>
                    </div>
                </div>
            ) : (
                <div>
                    <InnerTemplate houses={ houses } chartInfo={ chartInfo } title={ title } isRasi={ isRasi } isUpaGragam={ isUpaGragam } />
                </div>
            ) }


        </div>
    );
};


export default Kattam;
