import { useEffect, useState } from 'react';
import '../i18n';
import { ProfileModel } from '../models/ProfileModel';

import { useParams } from 'react-router-dom';
import JadhakamService from '../services/JadhakamService';
import ProfileService from '../services/ProfileService';
import { KattamUtil } from "../util/KattamUtil";
import Kattam from './Kattam';
import PersonDetail from './PersonDetail';

const PersonToday = () => {
    const { profileId } = useParams();

    const [ profileChart, setProfileChart ] = useState<any>();
    const [ todayChart, setTodayChart ] = useState<any>();

    useEffect(() => {

        if (profileId && Number(profileId) > 0) {
            getProfiles('');
        } else {
            getTodaysChart();
        }


    }, []);

    const getProfiles = async (profileKey: string) => {

        const profileService = ProfileService.getInstance();

        const _profiles: ProfileModel[] = await profileService.getProfiles(profileKey);

        if (_profiles && _profiles.length > 0) {
            const profile: ProfileModel = _profiles.find(o => o.id === Number(profileId))!;

            if (profile) {

                const jdService: JadhakamService = JadhakamService.getInstance();

                const data = await jdService.getPersonChart(profile);

                if (data) {
                    const kattamUtil: KattamUtil = new KattamUtil();

                    const _profileChart = kattamUtil.getKattam(data);

                    setProfileChart(_profileChart);
                    getTodaysChart();

                }
            }
        }
    };

    const getTodaysChart = async () => {

        const jdService: JadhakamService = JadhakamService.getInstance();
        const kattamUtil: KattamUtil = new KattamUtil();

        const now = new Date();
        const isoDate = now.toISOString().split('T')[ 0 ] + 'T00:00:00';
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        const _todayprofile = { birth_date: isoDate, birth_time: `${hours}:${minutes}`, location: 'Madurai' };

        const _todayData = await jdService.getTodayChart(_todayprofile);

        if (_todayData && _todayData.planetary_data) {
            const _todayChart = kattamUtil.getKattam(_todayData);

            setTodayChart(_todayChart);
        }

    };

    return (
        <div>
            { todayChart &&

                <div>
                    <div style={ { display: 'flex', justifyContent: 'space-between', padding: '10px' } }>
                        <div style={ { width: '45%', padding: '5px' } }>
                            <Kattam
                                chartInfo={ todayChart }
                                houses={ todayChart.rasi_houses }
                                isUpaGragam={ false }
                                hInfo={ true }
                                title="ராசி"
                                isRasi={ true }
                                outerPlanets={ todayChart.outer_planets }
                            />
                        </div>

                        <div style={ { width: '30%', display: 'inline-block', paddingRight: '10px' } }>
                            <div style={ { display: 'inline-block', marginRight: '50px' } }>
                                <br />
                                Tamil Year: { todayChart.general_info.tamil_year_name } <br />
                                Ragu: { todayChart.neram.rahu_kalam } <br />
                                Gulikai: { todayChart.neram.gulika_kalam } <br />
                            </div>
                            <div style={ { display: 'inline-block' } }>
                                Yama Kandam: { todayChart.neram.yama_gandam } <br />
                                Durmuhurtham:
                                <div style={ { display: 'inline-block' } }>
                                    { todayChart.neram.durmuhurtham.map((item, index) => (
                                        <span key={ index }>
                                            { item.start } - { item.end } ||
                                        </span>
                                    )) }
                                </div>
                            </div>
                        </div>


                    </div>

                    { profileChart &&
                        <PersonDetail chartInfo={ profileChart } category="dasa-info" /> }


                </div>
            }

        </div>
    );
};
export default PersonToday;