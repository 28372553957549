
// Constants
export const HOROSCOPE_CONSTANTS = {
    HOUSES: [
        { default_order: 9, name: '1வது வீடு', house_number: 1, info: 'Self' },
        { default_order: 10, name: '2வது வீடு', house_number: 2, info: 'Family' },
        { default_order: 11, name: '3வது வீடு', house_number: 3, info: 'Younger Sib' },
        { default_order: 17, name: '4வது வீடு', house_number: 4, info: 'Mother' },
        { default_order: 23, name: '5வது வீடு', house_number: 5, info: 'Punniyam' },
        { default_order: 29, name: '6வது வீடு', house_number: 6, info: 'Disease' },
        { default_order: 28, name: '7வது வீடு', house_number: 7, info: 'Wife / Partners' },
        { default_order: 27, name: '8வது வீடு', house_number: 8, info: 'Aayul' },
        { default_order: 26, name: '9வது வீடு', house_number: 9, info: 'Father' },
        { default_order: 20, name: '10வது வீடு', house_number: 10, info: 'Business' },
        { default_order: 14, name: '11வது வீடு', house_number: 11, info: 'Elder Sib / Profit' },
        { default_order: 8, name: '12வது வீடு', house_number: 12, info: 'Motsam / Virayam' },
    ],
    RASI: [
        { rasi_tamil: "மேஷம்", rasi_english: "Aries" },
        { rasi_tamil: "ரிஷபம்", rasi_english: "Taurus" },
        { rasi_tamil: "மிதுனம்", rasi_english: "Gemini" },
        { rasi_tamil: "கடகம்", rasi_english: "Cancer" },
        { rasi_tamil: "சிம்மம்", rasi_english: "Leo" },
        { rasi_tamil: "கன்னி", rasi_english: "Virgo" },
        { rasi_tamil: "துலாம்", rasi_english: "Libra" },
        { rasi_tamil: "விருச்சிகம்", rasi_english: "Scorpio" },
        { rasi_tamil: "தனுசு", rasi_english: "Sagittarius" },
        { rasi_tamil: "மகரம்", rasi_english: "Capricorn" },
        { rasi_tamil: "கும்பம்", rasi_english: "Aquarius" },
        { rasi_tamil: "மீனம்", rasi_english: "Pisces" },
    ],
    NERAM: [
        { day: "Sunday", tamil_day: "ஞாயிறு", rahu_kalam: "4:30 PM to 6:00 PM", gulika_kalam: "3:00 PM to 4:30 PM", yama_gandam: "12:00 Noon to 1:30 PM", durmuhurtham: [ { start: "4:24 PM", end: "5:12 PM" } ] },
        { day: "Monday", tamil_day: "திங்கள்", rahu_kalam: "7:00 AM to 8:30 AM", gulika_kalam: "1:30 PM to 3:00 PM", yama_gandam: "10:30 AM to 12:00 PM", durmuhurtham: [ { start: "12:24 PM", end: "1:12 PM" }, { start: "2:48 PM", end: "3:36 PM" } ] },
        { day: "Tuesday", tamil_day: "செவ்வாய்", rahu_kalam: "3:00 PM to 4:30 PM", gulika_kalam: "12:00 Noon to 1:30 PM", yama_gandam: "9:00 AM to 10:30 AM", durmuhurtham: [ { start: "8:24 AM", end: "9:12 AM" }, { start: "10:48 PM", end: "11:36 PM" } ] },
        { day: "Wednesday", tamil_day: "புதன்", rahu_kalam: "12:00 Noon to 1:30 PM", gulika_kalam: "10:30 AM to 12:00 PM", yama_gandam: "7:30 AM to 9:00 AM", durmuhurtham: [ { start: "11:36 AM", end: "12:24 PM" } ] },
        { day: "Thursday", tamil_day: "வியாழன்", rahu_kalam: "1:30 PM to 3:00 PM", gulika_kalam: "9:00 AM to 10:30 AM", yama_gandam: "6:00 AM to 7:30 AM", durmuhurtham: [ { start: "10:00 AM", end: "10:48 AM" }, { start: "2:48 PM", end: "3:36 PM" } ] },
        { day: "Friday", tamil_day: "வெள்ளி", rahu_kalam: "10:30 AM to 12:00 PM", gulika_kalam: "7:30 AM to 9:00 AM", yama_gandam: "3:00 PM to 4:30 PM", durmuhurtham: [ { start: "8:24 AM", end: "9:12 AM" }, { start: "12:24 PM", end: "1:12 PM" } ] },
        { day: "Saturday", tamil_day: "சனி", rahu_kalam: "9:00 AM to 10:30 AM", gulika_kalam: "6:00 AM to 7:30 AM", yama_gandam: "1:30 PM to 3:00 PM", durmuhurtham: [ { start: "7:36 AM", end: "8:24 AM" } ] }
    ]
};
