import axios from 'axios';
import { IncidentModel } from '../models/IncidentModel';
import { ProfileModel } from '../models/ProfileModel';

class ProfileService {
    private static instance: ProfileService;

    private profiles: ProfileModel[] = [];

    private profileId: number = 0;

    public static getInstance(): ProfileService {
        if (!ProfileService.instance) {
            ProfileService.instance = new ProfileService();
        }
        return ProfileService.instance;
    }

    fetchProfiles = async (profileKey: string) => {
        const url = `/py/profiles`;
        try {
            const { data } = await axios.post(url, { profile_key: profileKey });
            if (!data?.Error) {
                this.profiles = data;
            }

        } catch (error) {
            console.log(error);
        }

        if (this.profileId === 0 && this.profiles.length > 0) {
            this.profileId = this.profiles[ 0 ].id!;
        }

        return this.profiles;
    };

    getProfiles = async (profileKey: string) => {

        if (this.profiles && this.profiles.length === 0) {
            await this.fetchProfiles(profileKey);

        }

        return this.profiles;
    };

    saveProfile = async (profile: ProfileModel) => {
        try {
            delete profile.create_date;
            const { data } = await axios.post('/py/profile', { profile: profile });
            if (!data?.Error) {
                this.profiles = data.profiles;
                return this.profiles;
            }


        } catch (error) {
            console.log(error);
        }

        return [];

    };

    setProfileId = (id: number) => {
        this.profileId = id;
    };

    getProfileId = () => {
        return this.profileId;
    };

    getIncidents = async () => {

        const url = `/py/incidents`;
        try {
            const { data } = await axios.post(url, { profile_id: this.getProfileId() });
            if (!data?.Error) {
                return data;
            }

        } catch (error) {
            console.log(error);
        }

        return;
    };


    saveIncident = async (_incident: IncidentModel) => {
        try {

            const pcUrl = `/py/incident`;

            const { data } = await axios.post(pcUrl, { incident: _incident });

            if (!data?.Error) {
                return data.incidents;
            }
        } catch (error) {
            console.log(error);
        }

    };

}

export default ProfileService;