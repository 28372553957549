import axios from 'axios';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Login from './login/Login';

// Lazy-loading other components
// const PlanetMovement = lazy(() => import('./planets/PlanetMovement'));
const JadhakamMain = lazy(() => import('./jothidam/JadhakamMain'));
const Profile = lazy(() => import('./jothidam/Profile'));
const Locations = lazy(() => import('./jothidam/Locations'));
const PersonJadhakam = lazy(() => import('./jothidam/PersonJadhakam'));
const PersonToday = lazy(() => import('./jothidam/PersonToday'));
const PersonDivisional = lazy(() => import('./jothidam/PersonDivisional'));
const Incident = lazy(() => import('./jothidam/Incident'));

const Thithi = lazy(() => import('./panchangam/Thithi'));



let isAppEnabled = true;

if (window.location.origin.includes('localhost')) {
  axios.defaults.baseURL = 'http://127.0.0.1:6002';
}

axios.defaults.withCredentials = true;

// Error Notification Component
const ErrorNotification = ({ message }: { message: string; }) => (
  <div className="error-notification">{ message }</div>
);


const App = () => {
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ initialPath, setInitialPath ] = useState('/');

  const navigate = useNavigate();


  useEffect(() => {

    const username = localStorage.getItem('jd-username');
    const password = localStorage.getItem('jd-password');

    if (window.location.pathname && window.location.pathname.length > 3) {
      setInitialPath(window.location.pathname);
    }

    if (username && password) {
      const authHeaders = "Basic " + btoa(`${username}:${password}`);
      axios.defaults.headers.common[ 'Authorization' ] = authHeaders;

      handleInterceptor();

      setIsAuthenticated(true);

      if (window.location.pathname && window.location.pathname.length > 3 &&
        !window.location.pathname.includes('login')) {
        navigate(window.location.pathname);
      } else {
        navigate('/');
      }
    }




  }, []);

  const handleInterceptor = () => {
    const reqInterctor = axios.interceptors.request.use(
      (config) => {
        setErrorMessage('Loading data...');

        if (config.url && config.url.includes('hereapi')) {
          delete config.headers[ 'Authorization' ];
        }

        return config; // Always return the config
      },
      (error) => {
        return Promise.reject(error); // Handle errors
      }
    );

    const interceptor = axios.interceptors.response.use(
      response => {

        if (response.data && response.data.Error) {
          setErrorMessage(response.data.Error);
        } else if (response.data && response.data.Status) {
          setErrorMessage(response.data.Status);
        } else {
          setErrorMessage(null);
        }

        return response;
      },
      error => {
        // Clear loading message if an error occurs
        setErrorMessage(null); // Clear out loading message

        if (error.response && error.response.data && error.response.data.Error) {
          setErrorMessage(error.response.data.Error);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      axios.interceptors.request.eject(reqInterctor);
      axios.interceptors.response.eject(interceptor);
    };
  };

  const handleLogin = async () => {


    setIsAuthenticated(true);
    navigate(initialPath);

    // Use a callback or a context to navigate after login
  };

  const handleLogout = () => {
    localStorage.removeItem('jd-username');
    localStorage.removeItem('jd-password');
    delete axios.defaults.headers.common[ 'Authorization' ];
    setIsAuthenticated(false);
  };



  useEffect(() => {
    if (!isAppEnabled) {
      setErrorMessage('Access Restricted');
    }
  }, []);

  if (!isAppEnabled) {
    return <div>{ errorMessage }</div>;
  }

  return (
    <Suspense fallback={ <div>Loading...</div> }>
      { errorMessage && <ErrorNotification message={ errorMessage } /> }

      <Routes>
        <Route path="/login" element={ <Login onLogin={ handleLogin } /> } />
        <Route path="/" element={ isAuthenticated ? <Home onLogout={ handleLogout } /> : <Navigate to="/login" /> } />

        { isAuthenticated && (

          <Route path="jd" element={ <JadhakamMain onLogout={ handleLogout } /> }>
            <Route path="profile" element={ <Profile /> } />
            <Route path="locations" element={ <Locations /> } />
            <Route path="person-jd/:profileId" element={ <PersonJadhakam /> } />
            <Route path="today/:profileId" element={ <PersonToday /> } />
            <Route path="incident/:profileId" element={ <Incident /> } />
            <Route path="divisional/:profileId" element={ <PersonDivisional /> } />
          </Route>
        ) }

        { isAuthenticated && (
          <Route path="thithi" element={ <Thithi /> }>
          </Route>
        ) }

        {/* <Route path="planets" element={ <PlanetMovement /> } /> */ }
      </Routes>
    </Suspense>
  );
};

export default App;
