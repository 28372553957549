import axios from 'axios';
import { useState } from 'react';
import './Login.css'; // Import the CSS file

const Login = ({ onLogin }: { onLogin: () => void; }) => {
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ otp, setOtp ] = useState('');
    const [ error, setError ] = useState<string | null>(null);
    const [ isOtpSent, setIsOtpSent ] = useState(false); // Track if OTP has been sent

    const handleLogin = async () => {
        setError('SignIn in Progress.. ');
        signIn(false);
    };

    const handleRegister = async () => {
        setError('Registration in Progress.. ');
        signIn(true);
    };


    const signIn = async (isRegister: boolean) => {
        const authHeaders = "Basic " + btoa(`${username}:${password}`);
        axios.defaults.headers.common[ 'Authorization' ] = authHeaders;

        localStorage.removeItem('jd-username');
        localStorage.removeItem('jd-password');

        const url = isRegister ? `/py/register` : `/py/auth`;
        try {
            const { data } = await axios.get(url);

            if (!data || data.Error) {
                setError(data.Error);
                return;
            }

            if (data.Message && data.Message.includes("OTP sent")) {
                // If OTP is sent, show OTP input
                setIsOtpSent(true);
                setError(data.Message); // Clear previous errors
                return;
            }

            // Successful login if no OTP is needed
            localStorage.setItem('jd-username', username);
            localStorage.setItem('jd-password', password);
            onLogin();

        } catch (error) {
            console.error(error);
            setError("An error occurred. Please try again.");
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const url = `/py/verify_otp`;
            const { data } = await axios.post(url, { username, otp });

            if (!data || data.Error) {
                setError(data.Error);
                return;
            }

            // Successful OTP verification
            localStorage.setItem('jd-username', username);
            localStorage.setItem('jd-password', password);
            onLogin();

        } catch (error) {
            console.error(error);
            setError("Failed to verify OTP. Please try again.");
        }
    };

    return (
        <div className="login-container"> {/* Apply container class */ }
            <h2>Login</h2>
            { error && <div className="error-notification">{ error }</div> }

            <input
                type="text"
                value={ username }
                onChange={ (e) => setUsername(e.target.value) }
                placeholder="Username"
                required
                className="input-field" // Apply field class
                disabled={ isOtpSent } // Disable fields when OTP is being entered
            />
            <input
                type="password"
                value={ password }
                onChange={ (e) => setPassword(e.target.value) }
                placeholder="Password"
                required
                className="input-field" // Apply field class
                disabled={ isOtpSent } // Disable fields when OTP is being entered
            />

            { !isOtpSent ? (
                <div className='button-container'>
                    <button onClick={ handleRegister } className="button">Register</button> &nbsp;&nbsp;&nbsp;
                    <button onClick={ handleLogin } className="button">Login</button>
                </div> // Apply button class
            ) : (
                <>
                    <input
                        type="text"
                        value={ otp }
                        onChange={ (e) => setOtp(e.target.value) }
                        placeholder="Enter OTP"
                        required
                        className="input-field" // Apply field class
                    />
                    <div className='button-container'>
                        <button onClick={ handleVerifyOtp } className="button">Verify OTP</button> &nbsp;&nbsp;&nbsp;
                        <button onClick={ handleLogin } className="button">Resend OTP</button>
                    </div>

                </>
            ) }
        </div>
    );
};

export default Login;
