import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KattamUtil } from '../util/KattamUtil';
import './PersonDetail.css'; // Assuming you want to import CSS like in Angular

interface PersonDetailProps {
    chartInfo: any;
    category: string;
}

const PersonDetail: React.FC<PersonDetailProps> = ({ chartInfo = {}, category }) => {

    const [ chartDetail, setChartDetail ] = useState<any>({});

    const { t } = useTranslation();

    const kattamUtil = new KattamUtil();

    useEffect(() => {
        setChartDetail(chartInfo);
    }, [ chartInfo ]);

    const showBukthis = (selectedDasa) => {
        const updatedChartInfo = { ...chartInfo, selectedDasa };
        kattamUtil.setBukthis(updatedChartInfo);
        setChartDetail(updatedChartInfo);
    };

    const showAntarams = (selectedBukthi) => {
        const updatedChartInfo = { ...chartInfo, selectedBukthi };
        kattamUtil.setAntharams(updatedChartInfo);
        setChartDetail(updatedChartInfo);
    };

    return (
        <div className="container">

            { category === 'general' && chartDetail && chartDetail.general_info && (
                <>
                    <div className="block section" style={ { width: '50%' } }>
                        <h4>பொதுவான விவரங்கள்</h4>
                        <div className="detail">
                            <div className="detail-title">ஜாதகர் பெயர்</div>: &nbsp; { chartDetail.first_name || 'Today' } ({ chartDetail.age })
                        </div>
                        <div className="detail">
                            <div className="detail-title">பிறந்த தேதி</div>: &nbsp; { new Date(chartDetail.dob).toLocaleString('en-IN', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }) } ({ chartDetail.neram?.tamil_day })
                        </div>
                        <div className="detail">
                            <div className="detail-title">பிறந்த ஊர்</div>: &nbsp;{ chartDetail.birth_location }
                        </div>
                        <div className="detail">
                            <div className="detail-title">ராசி</div>: &nbsp; { t(`RASI_TR.${chartDetail.nakshatraInfo?.rasi_chart}`) } {/* Insert translation logic */ }
                        </div>
                        <div className="detail">
                            <div className="detail-title">நட்சத்திரம்</div>: &nbsp; { t(`NAK_TR.${chartDetail.nakshatraInfo?.nak_en}`) } - { chartDetail.nakshatraInfo?.padam }
                        </div>
                    </div>

                    <div className="block section" style={ { width: '50%' } }>
                        <h4>அடிப்படைக் கூறுகள்</h4>
                        <div className="detail">
                            <div className="detail-title">சூரிய உதயம்</div>: &nbsp; { new Date(chartDetail.general_info.sunrise_time).toLocaleTimeString('en-IN') }
                        </div>
                        <div className="detail">
                            <div className="detail-title">அஸ்தமனம்</div>: &nbsp; { new Date(chartDetail.general_info.sunset_time).toLocaleTimeString('en-IN') }
                        </div>
                        <div className="detail">
                            <div className="detail-title">திதி</div>: &nbsp; { chartDetail.general_info.tithi_tm }
                        </div>
                        <div className="detail">
                            <div className="detail-title">யோகம்</div>: &nbsp; { chartDetail.general_info.yoga_tm }
                        </div>
                        <div className="detail">
                            <div className="detail-title">கரணம்</div>: &nbsp;
                        </div>
                    </div>
                </>
            ) }

            <br />

            { chartDetail.planet_master_records && category === 'star-info' && (
                <>
                    <h4>நட்சத்திரக் கூறுகள்</h4>
                    <div style={ { fontWeight: '600' } }>
                        <div className="detail-planet" style={ { width: '10%', display: 'inline-block' } }>{ t(`TITLE_TR.Planet`) }</div>
                        <div style={ { width: '20%', display: 'inline-block', textAlign: 'end', paddingRight: '30px' } }>{ t(`TITLE_TR.Degree`) }</div>
                        <div style={ { width: '20%', display: 'inline-block', textAlign: 'end', paddingRight: '30px' } }>{ t(`TITLE_TR.Degree`) }</div>
                        <div style={ { width: '20%', display: 'inline-block' } }>{ t(`TITLE_TR.Rasi`) }</div>
                        <div style={ { width: '20%', display: 'inline-block' } }>{ t(`TITLE_TR.Nakshatra`) }</div>
                    </div>

                    { chartDetail.planet_master_records.map((pmr, index) => (
                        <div key={ index }>
                            <div className="detail-planet" style={ { width: '10%', display: 'inline-block' } }>{ t(`PLANETS_TR.${pmr[ 'nak_planet' ]}`) }</div>
                            <div style={ { width: '20%', display: 'inline-block', textAlign: 'end', paddingRight: '30px' } }>{ pmr[ 'full_degree_dms' ] }</div>
                            <div style={ { width: '20%', display: 'inline-block', textAlign: 'end', paddingRight: '30px' } }>{ pmr[ 'normal_degree_dm' ] }</div>
                            <div style={ { width: '20%', display: 'inline-block' } }>{ t(`RASI_TR.${pmr[ 'rasi_chart' ]}`) }</div>
                            <div style={ { width: '20%', display: 'inline-block' } }>{ t(`NAK_TR.${pmr.nak_en}`) } - { pmr[ 'padam' ] }</div>
                        </div>
                    )) }
                </>
            ) }

            { category === 'dasa-info' && chartDetail.dasas && (
                <>
                    <h4>{ t(`TITLE_TR.Dasa`) } ({ chartDetail.dasa_kaalam })</h4>
                    <div style={ { fontWeight: '500' } }>
                        <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Dasa`) }</div>
                        <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                        <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>Start Date</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>End Date</div>
                        <div style={ { display: 'inline-block', width: '12%' } }>Age Range</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>Kala Pakai</div>
                    </div>

                    { chartDetail.dasas.map((dasa, index) => (
                        <div key={ index } className={ dasa.is_current ? 'ct_dasa' : '' } onClick={ () => showBukthis(dasa) } style={ { cursor: 'pointer' } }>
                            <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${dasa[ 'd2' ]}`) }</div>
                            <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                            <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                            <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(dasa[ 'start_date' ]).toLocaleDateString('en-IN') }</div>
                            <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(dasa[ 'end_date' ]).toLocaleDateString('en-IN') }</div>
                            <div style={ { display: 'inline-block', width: '12%' } }>{ dasa[ 'age_start' ] } to { dasa[ 'age_end' ] }</div>
                            <div style={ { display: 'inline-block', width: '10%', backgroundColor: 'red' } }>{ dasa[ 'kala_pagai' ] ? dasa[ 'kala_pagai' ][ 'kala_pagai_puthi' ] : '' }</div>
                        </div>
                    )) }

                    <h4>{ t(`TITLE_TR.Dasa`) } { t(`TITLE_TR.Puthi`) } ({ chartDetail.bukthi_kaalam })</h4>
                    <div style={ { fontWeight: '500' } }>
                        <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Dasa`) }</div>
                        <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Puthi`) }</div>
                        <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>Start Date</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>End Date</div>
                        <div style={ { display: 'inline-block', width: '12%' } }>Age Range</div>
                        <div style={ { display: 'inline-block', width: '10%' } }>Kala Pakai</div>
                    </div>

                    { chartDetail.buthis.map((puthi, index) => (
                        <div key={ index } className={ puthi.is_current ? 'ct_dasa' : '' } onClick={ () => showAntarams(puthi) } style={ { cursor: 'pointer' } }>
                            <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${puthi[ 'd2' ]}`) }</div>
                            <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${puthi[ 'b2' ]}`) }</div>
                            <div style={ { display: 'inline-block', width: '9%' } }>&nbsp;</div>
                            <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(puthi[ 'start_date' ]).toLocaleDateString('en-IN') }</div>
                            <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(puthi[ 'end_date' ]).toLocaleDateString('en-IN') }</div>
                            <div style={ { display: 'inline-block', width: '12%' } }>{ puthi[ 'age_start' ] } to { puthi[ 'age_end' ] }</div>
                            <div style={ { display: 'inline-block', width: '10%', backgroundColor: 'red' } }>{ puthi[ 'kala_pagai' ] ? 'Yes' : '' }</div>
                        </div>
                    )) }

                    <br />

                    { chartDetail.antaramsFiltered && (
                        <>
                            <h4>{ t(`TITLE_TR.Antharan`) } ({ chartDetail.antaram_kaalam })</h4>
                            <div>
                                <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Dasa`) }</div>
                                <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Puthi`) }</div>
                                <div style={ { display: 'inline-block', width: '9%' } }>{ t(`TITLE_TR.Antharan`) }</div>
                                <div style={ { display: 'inline-block', width: '10%' } }>Start Date</div>
                                <div style={ { display: 'inline-block', width: '10%' } }>End Date</div>
                            </div>

                            { chartDetail.antaramsFiltered.map((antaram, index) => (
                                <div key={ index } className={ antaram.is_current ? 'ct_dasa' : '' }>
                                    <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${antaram[ 'd3' ]}`) }</div>
                                    <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${antaram[ 'b3' ]}`) }</div>
                                    <div style={ { display: 'inline-block', width: '9%' } }>{ t(`PLANETS_TR.${antaram[ 'a3' ]}`) }</div>
                                    <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(antaram[ 'start_date' ]).toLocaleDateString('en-IN') }</div>
                                    <div style={ { display: 'inline-block', width: '10%' } }>{ new Date(antaram[ 'end_date' ]).toLocaleDateString('en-IN') }</div>
                                </div>
                            )) }
                        </>
                    ) }
                </>
            ) }
        </div>
    );
};

export default PersonDetail;
