import axios from 'axios';
import { ProfileModel } from '../models/ProfileModel';

class JadhakamService {

    private static instance: JadhakamService;

    private personCharts = {};


    public static getInstance(): JadhakamService {
        if (!JadhakamService.instance) {
            JadhakamService.instance = new JadhakamService();
        }
        return JadhakamService.instance;
    }

    getPersonChart = async (profile: ProfileModel) => {
        try {

            if (profile && profile.id) {
                if (this.personCharts && this.personCharts[ profile.id ]) {
                    return this.personCharts[ profile.id ];
                }

                const pcUrl = `/py/person-chart-` + profile.first_name;

                const { data } = await axios.post(pcUrl, { profile: profile });

                if (!data?.Error) {
                    this.personCharts[ profile.id ] = data;
                    return data;
                }
            }

        } catch (error) {
            console.log(error);
        }

    };

    getTodayChart = async (opts: any) => {
        try {

            const pcUrl = `/py/today-chart`;

            const { data } = await axios.post(pcUrl, opts);

            if (!data?.Error) {
                return data;
            }

        } catch (error) {
            console.log(error);
        }

    };

    getThithiChart = async (opts: any) => {
        try {

            const pcUrl = `/py/thithi-chart`;

            const { data } = await axios.post(pcUrl, opts);

            if (!data?.Error) {
                return data;
            }

        } catch (error) {
            console.log(error);
        }

    };


}

export default JadhakamService;